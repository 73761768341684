import { VerisModule } from "@unlockdfinance/verislabs-web3";
import { app } from "../config/app.config";

export const verisModule = new VerisModule(
  app.ELEGIBLE_NETWORKS[0].CHAIN,
  app.ALCHEMY.alchemyApiKey,
  app.WALLET_CONNECT.projectId,
  app.ELEGIBLE_NETWORKS
);

export type SupportedChains = typeof verisModule.supportedChains;
export type SupportedChainNames = SupportedChains[number]["name"];
export type SupportedChainIds = SupportedChains[number]["id"];
export type SupportedChainHexIds = SupportedChains[number]["hexId"];

export const externalWalletModule = verisModule.walletModule;
