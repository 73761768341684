import safaryTrack from 'vendor/safary/safaryTrack'
import logger from 'utils/logger'

async function event(eventType: string, eventId: string) {
    try {
        await safaryTrack(eventType, eventId)

        logger.debug('TRACK', eventType, eventId)
    } catch (error) {
        logger.error('TRACK', eventType, eventId, 'failed', (error as Error).message)
    }
}

function click(eventId: string) {
    event('click', eventId)
}

const track = {
    event,
    click
}

export default track