import type { Log } from "viem";
import { Hash } from "./types/VerisChain";

export class Output<TData> {
  hash: Hash | null;
  data: TData | null;
  log: Log | null;

  constructor(
    hash: Hash | null,
    log: Log | null = null,
    data: TData | null = null
  ) {
    this.hash = hash;
    this.data = data;
    this.log = log;
  }
}
