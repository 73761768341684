const isDevelopment = process.env.NEXT_PUBLIC_NODE_ENV === "development";
const isLocal = process.env.NEXT_PUBLIC_NODE_ENV === "local";
const isProduction = process.env.NEXT_PUBLIC_NODE_ENV === "production";

const env = {
  isFakeMode: process.env.NEXT_PUBLIC_MODE === "fake",
  isLocal,
  isDevelopment,
  isProduction,
  publicAlchemyApiKey: isDevelopment
    ? "RBdFUFp4vMMk6wduis6BpAPOIVMAFfbs"
    : isLocal
    ? "XalhISVudH62OYYNogsN3c8fpBUAXDm9"
    : "ozWKwKZ7larDzr-mf9BD1bQAtwACe6RB",
  safaryProjectId: process.env.NEXT_PUBLIC_SAFARY_PROJECT_ID,
  logLevel: isProduction ? 3 : 0,
};

export default env;
