import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import {
  Erc20Currency,
  NativeCurrency,
} from "./types/currency/Currency";
import { NetworkConfig, app } from "config/app.config";
import { Address } from "@unlockdfinance/verislabs-web3";
import { SupportedChainIds, verisModule } from "clients/verisModule";
import { ICurrency } from "./types/currency/ICurrency";

class CurrenciesModule {
  private currenciesForNetwork: Map<SupportedChainIds, ICurrency[]> = new Map();
  private tokensForNetwork: Map<SupportedChainIds, ICurrency[]> = new Map();

  constructor(networks: NetworkConfig[]) {
    networks.forEach((network) => {
      this.currenciesForNetwork.set(
        network.CHAIN.id,
        network.CURRENCIES.map((currencyData) =>
          currencyData.type === "erc20" && currencyData.address
            ? // @ts-ignore
            new Erc20Currency(currencyData)
            : new NativeCurrency(currencyData)
        )
      );

      this.tokensForNetwork.set(
        network.CHAIN.id,
        network.TOKENS.map((currencyData) =>
          currencyData.type === "erc20" && currencyData.address
            ? // @ts-ignore
            new Erc20Currency(currencyData)
            : new NativeCurrency(currencyData)
        )
      );
    });
  }

  getCurrencies(chainId: SupportedChainIds) {
    const currencies = this.currenciesForNetwork.get(chainId)!;

    if (!currencies) {
      throw new Error(`Currencies not found for chain ${chainId}`);
    }

    return currencies;
  }

  getTokens(chainId: SupportedChainIds) {
    const tokens = this.tokensForNetwork.get(chainId);

    if (!tokens) {
      throw new Error(`Currencies not found for chain ${chainId}`);
    }

    return tokens;
  }

  getErc20Currencies(chainId: SupportedChainIds): Erc20Currency[] {
    const erc20Currencies: Erc20Currency[] = [];

    this.getCurrencies(chainId).forEach((currency) => {
      if (currency instanceof Erc20Currency) {
        erc20Currencies.push(currency);
      }
    });

    return erc20Currencies;
  }

  getDefaultCurrency(chainId: SupportedChainIds): Erc20Currency {
    const defaultCurrency = this.getCurrencies(chainId).find(
      (currency) => currency.name === "usdc"
    );

    if (!defaultCurrency) {
      throw new Error("Default currency not found");
    }

    return defaultCurrency as Erc20Currency;
  }

  getNativeCurrency(chainId: SupportedChainIds): NativeCurrency {
    return this.getCurrencies(chainId).find(
      (currency) => currency instanceof NativeCurrency
    ) as NativeCurrency;
  }

  getWrappedNativeCurrency(chainId: SupportedChainIds): Erc20Currency {
    return this.getErc20Currencies(chainId).find(
      (currency) => currency.isWrappedNative
    )!;
  }

  getErc20CurrencyByAddress(
    address: Address,
    chainId: SupportedChainIds
  ): Erc20Currency | null {
    const currency = this.getErc20Currencies(chainId).find((currency) =>
      equalIgnoreCase(currency.address, address)
    );

    return currency || null;
  }
}

export default new CurrenciesModule(app.ELEGIBLE_NETWORKS);
