import { bulkTransferAbi } from "./abis/bulkTransferAbi";
import { Address } from "viem";
import { WalletType } from "./types";
import { OptionsWriteMethod } from "@unlockdfinance/verislabs-web3";
import AbstractContract from "./AbstractContract";
import { externalWalletModule } from "clients/verisModule";

export default class BulkTransferContract extends AbstractContract<
  typeof bulkTransferAbi
> {
  constructor() {
    super({ contractName: "bulkTransfer" }, bulkTransferAbi);
  }

  batchTransferFrom(
    nfts: { contractAddress: Address; tokenId: bigint }[],
    to: Address,
    options?: OptionsWriteMethod,
  ) {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.batchTransferFrom([nfts, to]),
      options
    );
  }
}
