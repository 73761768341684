import { Address } from "viem";
import { delegationWalletRegistryAbi } from "./abis/delegationWalletRegistryAbi";
import AbstractContract from "./AbstractContract";
import { SupportedChainIds } from "clients/verisModule";

export class DelegationWalletRegistryContract extends AbstractContract<
  typeof delegationWalletRegistryAbi
> {
  constructor() {
    super({ contractName: "walletRegistry" }, delegationWalletRegistryAbi);
  }

  async getOwnerWalletAddresses(
    externalAccount: Address,
    chainId: SupportedChainIds
  ) {
    return (await this.viemReadContract(chainId)).read.getOwnerWalletAddresses([
      externalAccount,
    ]);
  }
}
