import { Address } from "viem";
import { auctionAbi } from "./abis/auctionAbi";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import {
  SupportedChainIds,
  externalWalletModule,
} from "../clients/verisModule";
import AbstractContract from "./AbstractContract";
import { SimpleNft } from "logic/types/nft/INft";

export default class AuctionContract extends AbstractContract<
  typeof auctionAbi
> {
  constructor(moduleId: string) {
    super({ moduleId }, auctionAbi);
  }

  async getMinBidPriceAuction(
    loanId: Address,
    assetId: Address,
    assetPrice: bigint,
    aggLoanPrice: bigint,
    aggLtv: bigint,
    chainId: SupportedChainIds
  ): Promise<bigint> {
    return (await this.viemReadContract(chainId)).read.getMinBidPriceAuction([
      loanId,
      assetId,
      assetPrice,
      aggLoanPrice,
      aggLtv,
    ]);
  }

  async getAmountToReedem(
    loanId: Address,
    assetIds: Address[],
    chainId: SupportedChainIds
  ) {
    return (await this.viemReadContract(chainId)).read.getAmountToReedem([
      loanId,
      assetIds,
    ]);
  }

  async bid(
    amountToPay: bigint,
    amountOfDebt: bigint,
    data: any,
    signature: any,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.bid([
          amountToPay,
          amountOfDebt,
          data,
          signature,
        ]),
      options
    );
  }

  async redeem(
    data: any,
    signature: any,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.redeem([data, signature]),
      options
    );
  }

  async finalize(
    claimOnUWallet: boolean,
    orderId: Address,
    nft: SimpleNft,
    data: any,
    signature: any,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.finalize([
          claimOnUWallet,
          orderId,
          nft,
          data,
          signature,
        ]),
      options
    );
  }
}
