import { Address, Hash } from "@unlockdfinance/verislabs-web3";
import { ICurrency } from "../currency/ICurrency";

export enum TransferMethod {
  CREDIT = "credit",
  DEBIT = "debit",
}

export interface ITransfer {
  txHash: Hash;
  method: "credit" | "debit";
  timestamp: number;
  from: Address;
  to: Address;
  value: bigint;
  currency: ICurrency;
  valueFormated: string;
  dateFormated: string;
}
