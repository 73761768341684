export const actionAbi = [
  {
    type: "constructor",
    inputs: [
      { name: "moduleId_", type: "uint256", internalType: "uint256" },
      { name: "moduleVersion_", type: "bytes32", internalType: "bytes32" },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "DOMAIN_SEPARATOR",
    inputs: [],
    outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "borrow",
    inputs: [
      { name: "amount", type: "uint256", internalType: "uint256" },
      {
        name: "assets",
        type: "tuple[]",
        internalType: "struct DataTypes.Asset[]",
        components: [
          { name: "collection", type: "address", internalType: "address" },
          { name: "tokenId", type: "uint256", internalType: "uint256" },
        ],
      },
      {
        name: "signAction",
        type: "tuple",
        internalType: "struct DataTypes.SignAction",
        components: [
          {
            name: "loan",
            type: "tuple",
            internalType: "struct DataTypes.SignLoanConfig",
            components: [
              { name: "loanId", type: "bytes32", internalType: "bytes32" },
              {
                name: "aggLoanPrice",
                type: "uint256",
                internalType: "uint256",
              },
              { name: "aggLtv", type: "uint256", internalType: "uint256" },
              {
                name: "aggLiquidationThreshold",
                type: "uint256",
                internalType: "uint256",
              },
              { name: "totalAssets", type: "uint88", internalType: "uint88" },
              { name: "nonce", type: "uint256", internalType: "uint256" },
              { name: "deadline", type: "uint256", internalType: "uint256" },
            ],
          },
          { name: "assets", type: "bytes32[]", internalType: "bytes32[]" },
          { name: "underlyingAsset", type: "address", internalType: "address" },
          { name: "nonce", type: "uint256", internalType: "uint256" },
          { name: "deadline", type: "uint256", internalType: "uint256" },
        ],
      },
      {
        name: "sig",
        type: "tuple",
        internalType: "struct DataTypes.EIP712Signature",
        components: [
          { name: "v", type: "uint8", internalType: "uint8" },
          { name: "r", type: "bytes32", internalType: "bytes32" },
          { name: "s", type: "bytes32", internalType: "bytes32" },
          { name: "deadline", type: "uint256", internalType: "uint256" },
        ],
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "calculateDigest",
    inputs: [
      { name: "nonce", type: "uint256", internalType: "uint256" },
      {
        name: "signAction",
        type: "tuple",
        internalType: "struct DataTypes.SignAction",
        components: [
          {
            name: "loan",
            type: "tuple",
            internalType: "struct DataTypes.SignLoanConfig",
            components: [
              { name: "loanId", type: "bytes32", internalType: "bytes32" },
              {
                name: "aggLoanPrice",
                type: "uint256",
                internalType: "uint256",
              },
              { name: "aggLtv", type: "uint256", internalType: "uint256" },
              {
                name: "aggLiquidationThreshold",
                type: "uint256",
                internalType: "uint256",
              },
              { name: "totalAssets", type: "uint88", internalType: "uint88" },
              { name: "nonce", type: "uint256", internalType: "uint256" },
              { name: "deadline", type: "uint256", internalType: "uint256" },
            ],
          },
          { name: "assets", type: "bytes32[]", internalType: "bytes32[]" },
          { name: "underlyingAsset", type: "address", internalType: "address" },
          { name: "nonce", type: "uint256", internalType: "uint256" },
          { name: "deadline", type: "uint256", internalType: "uint256" },
        ],
      },
    ],
    outputs: [{ name: "digest", type: "bytes32", internalType: "bytes32" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getAmountToRepay",
    inputs: [{ name: "loanId", type: "bytes32", internalType: "bytes32" }],
    outputs: [{ name: "amount", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getLoan",
    inputs: [{ name: "loanId", type: "bytes32", internalType: "bytes32" }],
    outputs: [
      {
        name: "",
        type: "tuple",
        internalType: "struct DataTypes.Loan",
        components: [
          { name: "loanId", type: "bytes32", internalType: "bytes32" },
          { name: "totalAssets", type: "uint88", internalType: "uint88" },
          {
            name: "state",
            type: "uint8",
            internalType: "enum Constants.LoanState",
          },
          { name: "underlyingAsset", type: "address", internalType: "address" },
          { name: "owner", type: "address", internalType: "address" },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getNonce",
    inputs: [{ name: "sender", type: "address", internalType: "address" }],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "moduleId",
    inputs: [],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "moduleVersion",
    inputs: [],
    outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "repay",
    inputs: [
      { name: "amount", type: "uint256", internalType: "uint256" },
      {
        name: "signAction",
        type: "tuple",
        internalType: "struct DataTypes.SignAction",
        components: [
          {
            name: "loan",
            type: "tuple",
            internalType: "struct DataTypes.SignLoanConfig",
            components: [
              { name: "loanId", type: "bytes32", internalType: "bytes32" },
              {
                name: "aggLoanPrice",
                type: "uint256",
                internalType: "uint256",
              },
              { name: "aggLtv", type: "uint256", internalType: "uint256" },
              {
                name: "aggLiquidationThreshold",
                type: "uint256",
                internalType: "uint256",
              },
              { name: "totalAssets", type: "uint88", internalType: "uint88" },
              { name: "nonce", type: "uint256", internalType: "uint256" },
              { name: "deadline", type: "uint256", internalType: "uint256" },
            ],
          },
          { name: "assets", type: "bytes32[]", internalType: "bytes32[]" },
          { name: "underlyingAsset", type: "address", internalType: "address" },
          { name: "nonce", type: "uint256", internalType: "uint256" },
          { name: "deadline", type: "uint256", internalType: "uint256" },
        ],
      },
      {
        name: "sig",
        type: "tuple",
        internalType: "struct DataTypes.EIP712Signature",
        components: [
          { name: "v", type: "uint8", internalType: "uint8" },
          { name: "r", type: "bytes32", internalType: "bytes32" },
          { name: "s", type: "bytes32", internalType: "bytes32" },
          { name: "deadline", type: "uint256", internalType: "uint256" },
        ],
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "event",
    name: "Borrow",
    inputs: [
      { name: "user", type: "address", indexed: true, internalType: "address" },
      {
        name: "loanId",
        type: "bytes32",
        indexed: true,
        internalType: "bytes32",
      },
      {
        name: "amount",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "totalAssets",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "token",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  { type: "event", name: "Genesis", inputs: [], anonymous: false },
  {
    type: "event",
    name: "InstallerInstallModule",
    inputs: [
      {
        name: "moduleId",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
      {
        name: "moduleImpl",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "moduleVersion",
        type: "bytes32",
        indexed: false,
        internalType: "bytes32",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "LoanCreated",
    inputs: [
      { name: "user", type: "address", indexed: true, internalType: "address" },
      {
        name: "loanId",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
      {
        name: "totalAssets",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "amount",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "borrowIndex",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ProxyCreated",
    inputs: [
      {
        name: "proxy",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "moduleId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Repay",
    inputs: [
      { name: "user", type: "address", indexed: true, internalType: "address" },
      {
        name: "loanId",
        type: "bytes32",
        indexed: true,
        internalType: "bytes32",
      },
      {
        name: "amount",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "assets",
        type: "bytes32[]",
        indexed: false,
        internalType: "bytes32[]",
      },
    ],
    anonymous: false,
  },
  { type: "error", name: "AmountExceedsDebt", inputs: [] },
  { type: "error", name: "AssetLocked", inputs: [] },
  { type: "error", name: "AssetsMismatch", inputs: [] },
  { type: "error", name: "InvalidArrayLength", inputs: [] },
  { type: "error", name: "InvalidAssetAmount", inputs: [] },
  { type: "error", name: "InvalidCurrentLiquidationThreshold", inputs: [] },
  { type: "error", name: "InvalidCurrentLtv", inputs: [] },
  { type: "error", name: "InvalidLoanOwner", inputs: [] },
  { type: "error", name: "InvalidRecoveredAddress", inputs: [] },
  { type: "error", name: "InvalidUnderlyingAsset", inputs: [] },
  { type: "error", name: "LoanNotActive", inputs: [] },
  { type: "error", name: "LoanNotUpdated", inputs: [] },
  { type: "error", name: "NotAssetOwner", inputs: [] },
  { type: "error", name: "NotEqualDeadline", inputs: [] },
  { type: "error", name: "NotEqualSender", inputs: [] },
  { type: "error", name: "NotValidReserve", inputs: [] },
  { type: "error", name: "SenderZeroAddress", inputs: [] },
  { type: "error", name: "UnhealtyLoan", inputs: [] },
  { type: "error", name: "UnlockdWalletNotFound", inputs: [] },
] as const;
