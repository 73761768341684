import {
  Chain as ViemChain,
  createPublicClient,
  http,
  TransactionReceipt,
} from "viem";
import { defineChain } from "viem";
import { mainnet, sepolia, polygonAmoy } from "@wagmi/core/chains";
import { WalletModule } from "./WalletModule";
import {
  Config,
  GetPublicClientReturnType,
  createConfig,
  injected,
} from "@wagmi/core";
import { walletConnect } from "@wagmi/connectors";
import { Address, Hash, VerisChain, VerisChainId } from "./types";
import IVerisModule, {
  GetSupportedChainsFromNetworkKeys,
} from "./IVerisModule";
import VerisStorage from "./utils/VerisStorage";
import { Output } from "./Output";
import SafeApiKit from "@safe-global/api-kit";
import ChainlinkPriceFeed from "./contracts/ChainlinkPriceFeed";
import { CONTRACT_ADDRESSES, NetworkAddresses } from "./networkConfig/config";
import { VerisNetwork } from "./types/VerisNetwork";
import { Alchemy, Network } from "alchemy-sdk";
import { mainnetConfig } from "config/mainnet.config";
import { sepoliaApp } from "config/sepolia.config";
import { polygonAmoyApp } from "config/polygon-amoy.config";
import { SupportedChainIds } from "clients/verisModule";

const getViemChain = (
  chainId: VerisChainId
): typeof sepolia | typeof mainnet | typeof polygonAmoy => {
  switch (chainId) {
    case 11155111:
      return sepolia;
    case 1:
      return mainnet;
    case 80002:
      return polygonAmoy;
  }
};

const getAlchemyChain = (chainId: VerisChainId) => {
  switch (chainId) {
    case 11155111:
      return Network.ETH_SEPOLIA;
    case 1:
      return Network.ETH_MAINNET;
    case 80002:
      return Network.MATIC_AMOY;
  }
};

export default class VerisModule<
  TContractAddresses extends Record<string, Address>,
  TNetworks extends readonly VerisNetwork<TContractAddresses>[]
> implements IVerisModule<TContractAddresses, TNetworks> {
  private alchemyApiKey: string;
  private wcProjectId: string;
  private publicClients: Record<
    VerisChainId,
    GetPublicClientReturnType | null
  > = {
      "1": null,
      "11155111": null,
      "80002": null,
    };
  private alchemyClients: Record<VerisChainId, Alchemy | null> = {
    "1": null,
    "11155111": null,
    "80002": null,
  };
  private networkAddresses: NetworkAddresses;

  chain: { viemChain: ViemChain; data: VerisChain };

  wagmiConfig: Config;
  safeService: SafeApiKit;
  walletModule: WalletModule<TContractAddresses, TNetworks>;
  storage: {
    local: VerisStorage | null;
    session: VerisStorage | null;
  };
  networks: TNetworks;
  readonly supportedChains: GetSupportedChainsFromNetworkKeys<TNetworks>;

  constructor(
    defaultChain: VerisChain,
    alchemyApiKey: string,
    wcProjectId: string,
    networks: TNetworks
  ) {
    this.alchemyApiKey = alchemyApiKey;
    this.wcProjectId = wcProjectId;

    const defaultChainData = networks.find(
      (network) => network.CHAIN.id === defaultChain.id
    )?.CHAIN;

    if (!defaultChainData) {
      throw new Error(`Chain ${defaultChain.name} not found`);
    }

    this.networks = networks;

    this.chain = this.setChain(defaultChain.id);

    this.networkAddresses = CONTRACT_ADDRESSES[defaultChain.name];

    // @ts-ignore
    this.supportedChains = [
      ...networks.map((network) => network.CHAIN),
    ] as const;

    const viemChains = networks.map((network) =>
      getViemChain(network.CHAIN.id)
    ) as [ReturnType<typeof getViemChain>];

    this.wagmiConfig = createConfig({
      connectors: [injected(), walletConnect({ projectId: this.wcProjectId })],
      chains: viemChains,
      // @ts-ignore
      client: () => {
        return this.getPublicClient();
      },
    });

    if (typeof window !== "undefined") {
      this.storage = {
        local: new VerisStorage(this, localStorage),
        session: new VerisStorage(this, sessionStorage),
      };
    } else {
      this.storage = {
        local: null,
        session: null,
      };
    }

    this.safeService = new SafeApiKit({
      chainId: BigInt(this.chain.data.id),
    });

    this.walletModule = new WalletModule(this);

    // this.walletModule.subscribe((_, chainId, __, ___) => {
    //   if (chainId !== null) {
    //     try {
    //       this.setChain(chainId);
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   }
    // });
  }

  get isMainnetConnected(): boolean {
    return this.chain.data.name === "mainnet";
  }

  get mainnetNetworkConfig(): TNetworks[number] {
    const network = this.networks.find((network) => network.CHAIN.id === 1);

    if (!network) {
      throw new Error("Mainnet network not found");
    }

    return network;
  }

  get currentNetworkConfig(): TNetworks[number] {
    const network = this.networks.find(
      (network) => network.CHAIN.id === this.chain.data.id
    );

    if (!network) {
      throw new Error(`Network ${this.chain.data.name} not found`);
    }

    return network;
  }

  getPublicClient(chainId: VerisChainId = this.chain.data.id) {
    const client = this.publicClients[chainId];
    if (client) return client;

    const newClient = createPublicClient({
      chain: getViemChain(chainId),
      transport: http(
        `${this.getNetwork(chainId).ALCHEMY.rpcUrl}/${this.alchemyApiKey}`
      ),
    });

    this.publicClients[chainId] = newClient;

    return newClient;
  }

  get publicMainnetClient() {
    const client = this.publicClients[1];
    if (client) return client;

    const newClient = createPublicClient({
      chain: getViemChain(1),
      transport: http(
        `${this.mainnetNetworkConfig.ALCHEMY.rpcUrl}/${this.alchemyApiKey}`
      ),
    });

    this.publicClients["1"] = newClient;

    return newClient;
  }

  getAlchemyClient(chainId: VerisChainId = this.chain.data.id) {
    const client = this.alchemyClients[chainId];
    if (client) return client;

    const newClient = new Alchemy({
      apiKey: this.alchemyApiKey,
      network: getAlchemyChain(chainId),
    });

    this.alchemyClients[chainId] = newClient;

    return newClient;
  }

  get alchemyMainnetClient() {
    const client = this.alchemyClients[1];
    if (client) return client;

    const newClient = new Alchemy({ apiKey: this.alchemyApiKey });

    this.alchemyClients[1] = newClient;

    return newClient;
  }

  get chainName(): string {
    return this.chain.viemChain.name;
  }

  getContractAddresses(
    chainId: VerisChainId = this.chain.data.id
  ): TNetworks[number]["CONTRACT_ADDRESSES"] {
    return this.getNetwork(chainId).CONTRACT_ADDRESSES;
  }

  getNetwork(chainId: VerisChainId): TNetworks[number] {
    const network = this.networks.find(
      (network) => network.CHAIN.id === chainId
    );

    if (!network) {
      throw new Error(`Network with id ${chainId} not found`);
    }

    return network;
  }

  getContractAddress(
    contractName: keyof TNetworks[number]["CONTRACT_ADDRESSES"],
    chainId: VerisChainId = this.chain.data.id
  ) {
    return this.getContractAddresses(chainId)[contractName];
  }

  setChain(id: number) {
    const network = this.networks.find((network) => network.CHAIN.id === id);

    if (!network) {
      throw new Error(`Chain with id ${id} not found`);
    }

    return (this.chain = {
      viemChain: getViemChain(id as VerisChainId),
      data: network.CHAIN,
    });
  }

  lookUpClient(
    type: "readOnly" | "mainnet" | "write",
    chainId: VerisChainId = this.chain.data.id
  ) {
    if (type === "write") {
      if (!this.walletModule.client) {
        throw new Error("Wallet module client is not initialized");
      }

      return this.walletModule.client;
    } else if (type === "mainnet") {
      return this.publicMainnetClient;
    } else {
      return this.getPublicClient(chainId);
    }
  }

  async waitForTransactionReceipt<TData>(
    hash: Hash,
    topic?: Hash
  ): Promise<Output<TData>> {
    const client = this.lookUpClient("readOnly");

    return new Promise<Output<TData>>(async (resolve, reject) => {
      const txReceipt: TransactionReceipt =
        // @ts-ignore
        await client.waitForTransactionReceipt({
          hash,
        });

      if (txReceipt && txReceipt.status === "success") {
        let log;

        if (topic) {
          log = txReceipt.logs.find((log) => log.topics[0] === topic);

          if (!log) reject(new Error("log not found"));
        }

        resolve(new Output(txReceipt.transactionHash, log));
      } else {
        reject(new Error("transaction failed"));
      }
    });
  }

  async getDollarRate(): Promise<bigint> {
    const chainlinkContract = new ChainlinkPriceFeed(
      this,
      this.networkAddresses.chainLinkEthUsdPriceFeed
    );

    return chainlinkContract.latestAnswer();
  }

  async waitForSafeExecution(
    smartAddress: Address,
    safeTxHash: Address,
    pollingTime: number = 5000
  ): Promise<Hash> {
    return new Promise<Hash>((resolve, reject) => {
      const getTransactionHash = async (hash: Hash) => {
        try {
          const tx = await this.safeService.getTransaction(hash);

          if (!tx.transactionHash) {
            const allTxs = await this.safeService.getAllTransactions(
              smartAddress
            );

            const txsWithSameNonce = allTxs.results.filter(
              // @ts-ignore
              (_tx) => _tx.nonce === tx.nonce
            );

            if (
              txsWithSameNonce.length > 1 &&
              txsWithSameNonce.some(
                // @ts-ignore
                (_tx) => _tx.data === null && _tx.isExecuted
              )
            ) {
              reject(new Error("Transaction rejected"));
            }

            setTimeout(() => getTransactionHash(hash), pollingTime);
          } else {
            resolve(tx.transactionHash as Hash);
          }
        } catch (err) {
          reject(err);
        }
      };

      getTransactionHash(safeTxHash);
    });
  }
}
