import { Address } from "viem";
import { unlockdAbi } from "./abis/unlockdAbi";
import AbstractContract, { UnlockdContractName } from "./AbstractContract";
import { SupportedChainIds } from "clients/verisModule";

export default class UnlockdContract extends AbstractContract<
  typeof unlockdAbi
> {
  constructor() {
    super({ contractName: "unlockd" }, unlockdAbi);
  }

  async moduleIdToProxy(
    moduleId: bigint,
    chainId: SupportedChainIds
  ): Promise<Address> {
    return (await this.viemReadContract(chainId)).read.moduleIdToProxy([
      moduleId,
    ]);
  }
}
