import { AssetTransfersWithMetadataResult } from "alchemy-sdk";
import { ITransfer, TransferMethod } from "./ITransfer";
import { Address, Hash } from "@unlockdfinance/verislabs-web3";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import Transfer from "./Transfer";
import currenciesModule from "logic/CurrenciesModule";
import { SupportedChainIds } from "clients/verisModule";

export default class TransferFactory {
  static create(
    transferFromAlchemy: AssetTransfersWithMetadataResult,
    walletAddress: Address,
    chainId: SupportedChainIds
  ): ITransfer {
    const method = equalIgnoreCase(transferFromAlchemy.from, walletAddress)
      ? TransferMethod.DEBIT
      : TransferMethod.CREDIT;

    const currency = transferFromAlchemy.rawContract.address
      ? currenciesModule.getErc20CurrencyByAddress(
        transferFromAlchemy.rawContract.address as Address,
        chainId
      )!
      : currenciesModule.getNativeCurrency(chainId);

    return new Transfer({
      txHash: transferFromAlchemy.hash as Hash,
      method,
      timestamp: new Date(
        transferFromAlchemy.metadata.blockTimestamp
      ).getTime(),
      from: transferFromAlchemy.from as Hash,
      to: transferFromAlchemy.to as Hash,
      value: BigInt(
        transferFromAlchemy.value
          ? transferFromAlchemy.value * 10 ** currency.decimals
          : 0
      ),
      currency,
    });
  }
}
