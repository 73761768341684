import { Address } from "@unlockdfinance/verislabs-web3";
import env from "env";
import { UnlockdCollectionData } from "logic/types/collection/ICollection";
import { mainnetConfig } from "config/mainnet.config";
import { sepoliaApp } from "config/sepolia.config";
import {
  ChainName,
  CurrencyData,
} from "vendor/@unlockdfinance/verislabs-web3/src/types";
import { VerisNetwork } from "vendor/@unlockdfinance/verislabs-web3/src/types/VerisNetwork";
import { polygonAmoyApp } from "./polygon-amoy.config";

type ContractAddresses = Readonly<{
  wrappedToken: Address;
  pushChannel: Address;
  unlockd: Address;
  uTokenFactory: Address;
  walletFactory: Address;
  walletRegistry: Address;
  bulkTransfer: Address;
  earnWethGateway: Address;
  delegationController: Address;
}>;

export type ContractAddressesNames = keyof ContractAddresses;

export type NetworkConfig = VerisNetwork<ContractAddresses> &
  Readonly<{
    FAUCET_COLLECTIONS: readonly UnlockdCollectionData[];
    COLLECTIONS: readonly UnlockdCollectionData[];
    FUNCTIONALITIES: {
      faucet: boolean;
      "eth-wrapper": boolean;
    };
    CURRENCIES: readonly CurrencyData[];
    TOKENS: readonly CurrencyData[];
    images: {
      srcPrimary: string;
      srcSecondary: string;
      srcTertiary: string;
    };
    RESERVOIR_API_URL: string;
    UNLOCKD_API_URL: string;
  }>;

export type App = {
  ELEGIBLE_NETWORKS: NetworkConfig[];
  UNLOCKD_MODULES: {
    INSTALLER: string;
    MANAGER: string;
    ACTION: string;
    AUCTION: string;
    MARKET: string;
    BUYNOW: string;
    SELLNOW: string;
  };
  ALCHEMY: {
    mainnetRpcUrl: string;
    alchemyApiKey: string;
  };
  LOCKEYS_ADDRESS: Address;
  MAX_NFTS_FOR_A_USER: number;
  TOKEN_ID_RANGES_TEAM: string;
  TOKEN_ID_RANGES_KEY_SUPPORTER: string;
  TOKEN_ID_RANGES_ONE_ON_ONE: string;
  DATE_TO_FAUCET_TEAM: string;
  DATE_TO_FAUCET_ONE_ON_ONE: string;
  DATE_TO_FAUCET_KEY_SUPPORTER: string;
  DATE_TO_FAUCET_HOLDER: string;
  DATE_TO_FAUCET_NON_HOLDER: string;
  DEFAULT_LIQ_THRESHOLD_VALUE: bigint;
  WALLET_CONNECT: {
    projectId: string;
  };
};

// const networkConfig: NetworkConfig =
//   env.isDevelopment || env.isLocal ? sepoliaApp : mainnetConfig;

export const app: App = {
  ELEGIBLE_NETWORKS: [mainnetConfig, sepoliaApp, polygonAmoyApp] as const,
  ALCHEMY: {
    mainnetRpcUrl: "https://eth-mainnet.g.alchemy.com/v2",
    alchemyApiKey:
      typeof window === "undefined"
        ? process.env.SERVER_ALCHEMY_KEY!
        : env.publicAlchemyApiKey,
    // TODO: handle this
    // ...networkConfig.ALCHEMY,
  },
  UNLOCKD_MODULES: {
    INSTALLER: "1",
    MANAGER: "2",
    ACTION: "3",
    AUCTION: "4",
    MARKET: "5",
    BUYNOW: "6",
    SELLNOW: "7",
  },
  LOCKEYS_ADDRESS: "0x9a29a9DBC70eA932637216A2BF9EbE7E60023798",
  MAX_NFTS_FOR_A_USER: 2,
  TOKEN_ID_RANGES_TEAM: "51/75|1453/1459|1983/1983|668/668",
  TOKEN_ID_RANGES_KEY_SUPPORTER: "76/590",
  TOKEN_ID_RANGES_ONE_ON_ONE: "1/50",
  DATE_TO_FAUCET_TEAM: "12/01/2022",
  DATE_TO_FAUCET_ONE_ON_ONE: "12/06/2022",
  DATE_TO_FAUCET_KEY_SUPPORTER: "12/06/2022",
  DATE_TO_FAUCET_HOLDER: "12/06/2022",
  DATE_TO_FAUCET_NON_HOLDER: "12/15/2022",
  DEFAULT_LIQ_THRESHOLD_VALUE: BigInt(8500),
  WALLET_CONNECT: {
    projectId: "ec934ada88527af0e297054305451159",
  },
} as const;

export function isFaucetEnabled(chainName: ChainName) {
  const networkConfig = app.ELEGIBLE_NETWORKS.find(
    (network) => network.CHAIN.name === chainName
  );

  if (!networkConfig) {
    throw new Error(`Chain ${chainName} not found`);
  }

  return networkConfig.FUNCTIONALITIES.faucet;
}
