import { useContext } from "react";
import { AlertContext } from "../providers/contexts";

const useAlertNotification = () => {
  const { alertNotification } = useContext(AlertContext);

  return alertNotification!;
};

export default useAlertNotification;
