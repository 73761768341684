import { VerisChain } from "@unlockdfinance/verislabs-web3";
import { NetworkConfig } from "config/app.config";
import {
  CollectionCategory,
  UnlockdCollectionData,
} from "logic/types/collection/ICollection";
import {
  CurrencyData,
  Erc20CurrencyData,
} from "vendor/@unlockdfinance/verislabs-web3/src/types";

const USDC_CURRENCY: Erc20CurrencyData = {
  decimals: 6,
  decimalsToShow: 2,
  srcPrimary: "/assets/images/tokens/usdc.png",
  srcSecondary: "/assets/images/tokens/usdc-secondary.png",
  name: "usdc",
  label: "USDC",
  address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  chainlinkAddress: "0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6",
  type: "erc20",
  chainId: 1,
};

const ETH_CURRENCY: CurrencyData = {
  decimals: 18,
  decimalsToShow: 4,
  srcPrimary: "/assets/images/tokens/eth.png",
  srcSecondary: "/assets/images/tokens/eth-secondary.png",
  name: "eth",
  label: "ETH",
  chainlinkAddress: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
  type: "native",
  chainId: 1,
};

const UNIKURA_COLLECTION: UnlockdCollectionData = {
  address: "0xEA89a88284fF9a9A9A54F4c43Fc4efbF099e992F",
  name: "Unikura Collectibles",
  category: CollectionCategory.WATCHES,
  currenciesSupported: [USDC_CURRENCY],
  genericImage:
    "https://app.unikura.xyz/api/metadata/ethereum/0xEA89a88284fF9a9A9A54F4c43Fc4efbF099e992F/81391390257499604695478422093995325247002886964995876573498734894400974422878",
};

const WATCHES_COLLECTION: UnlockdCollectionData = {
  address: "0xd7AB81881c8a0A8fbfDa70072D56ac6D7b3c3EfF",
  name: "Watch-backed NFTs",
  category: CollectionCategory.WATCHES,
  currenciesSupported: [USDC_CURRENCY],
  genericImage:
    "https://ipfs.io/ipfs/QmUdZ6GEfxDH2mbp3rBcHjPrQoaczx1ghQt12yvyoY71ig",
};

export const mainnetConfig: NetworkConfig = {
  FAUCET_COLLECTIONS: [],
  COLLECTIONS: [UNIKURA_COLLECTION, WATCHES_COLLECTION],
  CONTRACT_ADDRESSES: {
    wrappedToken: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    unlockd: "0xcD16AD66f4786a9224f53Af13987Fc2ED6FdE0Cb",
    uTokenFactory: "0x25299e9Ec27c242465587B8A2Aa70bcE02636cDA",
    walletFactory: "0xaE00dF7e92923f9c2ADCdf7C5E9F16F984cf7379",
    walletRegistry: "0x715988AfCbAEF9F99A6796f6d6699EeDDB48EB20",
    bulkTransfer: "0x0c331e00703a9857819fa1Eb72aA3d4DE0f6f725",
    pushChannel: "0x9fa5b8F350c08d7F9802cE9D7db0f322946e9C80",

    // NOT IN USE
    earnWethGateway: "0x0000000000000000000000000000000000000000",
    delegationController: "0x0000000000000000000000000000000000000000",
  },
  ALCHEMY: {
    rpcUrl: "https://eth-mainnet.g.alchemy.com/v2",
    apiUrl: "https://eth-mainnet.g.alchemy.com/nft/v2",
  },
  CHAIN: {
    name: "mainnet",
    hexId: "0x1",
    id: 1,
  },
  FUNCTIONALITIES: {
    faucet: false,
    "eth-wrapper": false,
  },
  CURRENCIES: [ETH_CURRENCY, USDC_CURRENCY],
  TOKENS: [ETH_CURRENCY, USDC_CURRENCY],
  images: {
    srcPrimary: "/assets/images/tokens/eth.svg",
    srcSecondary: "/assets/images/tokens/eth-secondary.png",
    srcTertiary: "/assets/images/tokens/eth.png"
  },
  RESERVOIR_API_URL: "https://api.reservoir.tools",
  UNLOCKD_API_URL: "https://api.unlockd.finance",
  // UNLOCKD_API_URL: "https://ethereum-mainnet.unlockd.finance",
} as const;
