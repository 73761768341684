export const delegationWalletRegistryAbi = [
  {
    type: "function",
    name: "_walletFactory",
    inputs: [],
    outputs: [{ name: "", type: "address", internalType: "address" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getOwnerWalletAddresses",
    inputs: [{ name: "_owner", type: "address", internalType: "address" }],
    outputs: [{ name: "", type: "address[]", internalType: "address[]" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getOwnerWalletAt",
    inputs: [
      { name: "_owner", type: "address", internalType: "address" },
      { name: "_index", type: "uint256", internalType: "uint256" },
    ],
    outputs: [
      {
        name: "",
        type: "tuple",
        internalType: "struct IDelegationWalletRegistry.Wallet",
        components: [
          { name: "wallet", type: "address", internalType: "address" },
          { name: "owner", type: "address", internalType: "address" },
          { name: "guard", type: "address", internalType: "address" },
          { name: "guardOwner", type: "address", internalType: "address" },
          { name: "delegationOwner", type: "address", internalType: "address" },
          { name: "protocolOwner", type: "address", internalType: "address" },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getWallet",
    inputs: [{ name: "_wallet", type: "address", internalType: "address" }],
    outputs: [
      {
        name: "",
        type: "tuple",
        internalType: "struct IDelegationWalletRegistry.Wallet",
        components: [
          { name: "wallet", type: "address", internalType: "address" },
          { name: "owner", type: "address", internalType: "address" },
          { name: "guard", type: "address", internalType: "address" },
          { name: "guardOwner", type: "address", internalType: "address" },
          { name: "delegationOwner", type: "address", internalType: "address" },
          { name: "protocolOwner", type: "address", internalType: "address" },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "owner",
    inputs: [],
    outputs: [{ name: "", type: "address", internalType: "address" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "renounceOwnership",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setFactory",
    inputs: [
      { name: "walletFactory", type: "address", internalType: "address" },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setWallet",
    inputs: [
      { name: "_wallet", type: "address", internalType: "address" },
      { name: "_owner", type: "address", internalType: "address" },
      { name: "_guard", type: "address", internalType: "address" },
      { name: "_guardOwner", type: "address", internalType: "address" },
      { name: "_delegationOwner", type: "address", internalType: "address" },
      { name: "_protocolOwner", type: "address", internalType: "address" },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "transferOwnership",
    inputs: [{ name: "newOwner", type: "address", internalType: "address" }],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        name: "previousOwner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "newOwner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "error",
    name: "DelegationWalletRegistry__onlyFactoryOrOwner",
    inputs: [],
  },
  {
    type: "error",
    name: "DelegationWalletRegistry__setFactory_invalidAddress",
    inputs: [],
  },
  {
    type: "error",
    name: "DelegationWalletRegistry__setWallet_invalidDelegationOwnerAddress",
    inputs: [],
  },
  {
    type: "error",
    name: "DelegationWalletRegistry__setWallet_invalidOwnerAddress",
    inputs: [],
  },
  {
    type: "error",
    name: "DelegationWalletRegistry__setWallet_invalidProtocolOwnerAddress",
    inputs: [],
  },
  {
    type: "error",
    name: "DelegationWalletRegistry__setWallet_invalidWalletAddress",
    inputs: [],
  },
] as const;
