class Logger {
    static DEBUG = 0
    static INFO = 1
    static WARN = 2
    static ERROR = 3
    static FATAL = 4

    level: number

    constructor() {
        this.level = Logger.DEBUG
    }

    #formatMessage(message: string) {
        return `${message}`
    }

    debug(...messages: Array<String>) {
        if (this.level < Logger.INFO)
            console.debug(`%c${this.#formatMessage(messages.join(' '))} ${new Date().toISOString()}`, 'color: green')
    }

    info(...messages: Array<String>) {
        if (this.level < Logger.WARN)
            console.info(`%c${this.#formatMessage(messages.join(' '))}`, 'color: blue')
    }

    warn(...messages: Array<String>) {
        if (this.level < Logger.ERROR)
            console.warn(`%c${this.#formatMessage(messages.join(' '))}`, 'color: orange')
    }

    error(...messages: Array<String>) {
        if (this.level < Logger.FATAL)
            console.error(`%c${this.#formatMessage(messages.join(' '))}`, 'color: red')
    }

    fatal(...messages: Array<String>) {
        if (this.level === Logger.FATAL)
            console.error(`%c${this.#formatMessage(messages.join(' '))}`, 'background-color: red; color: white')
    }
}

export default Logger