import {
  SupportedChainHexIds,
  SupportedChainIds,
  SupportedChainNames,
  SupportedChains,
  verisModule,
} from "clients/verisModule";

export default class Chain {
  static instances: Map<SupportedChainIds, Chain> = new Map();

  name: SupportedChainNames;
  id: SupportedChainIds;
  hexId: SupportedChainHexIds;

  private constructor({ name, id, hexId }: SupportedChains[number]) {
    this.name = name;
    this.id = id;
    this.hexId = hexId;
  }

  static get(chainId: SupportedChainIds): Chain {
    if (this.instances.has(chainId)) {
      return this.instances.get(chainId)!;
    }

    const chainData = verisModule.supportedChains.find(
      (chain) => chain.id === chainId
    );

    if (!chainData) {
      throw new Error(`Chain with id ${chainId} not found`);
    }

    this.instances.set(chainId, new Chain(chainData));

    return new Chain(chainData);
  }

  static getChainByName(name: SupportedChainNames): Chain {
    const chainData = verisModule.supportedChains.find(
      (chain) => chain.name === name
    );

    if (!chainData) {
      throw new Error(`Chain with name ${name} not found`);
    }

    const chainId = chainData.id;

    return this.get(chainId);
  }

  get isTestnet(): boolean {
    return this.id !== 1;
  }

  get isPolygon(): boolean {
    return this.id === 80002;
  }

  get scanUrl(): string {
    const base = "https://";

    const networkPart =
      this.id === 11155111 ? "sepolia." : this.id === 80002 ? "amoy." : "";

    const scanPart = this.scan;

    return `${base}${networkPart}${scanPart}`;
  }

  get scan() {
    return this.isPolygon ? "polygonscan.com" : "etherscan.io";
  }
}
