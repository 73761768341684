import { createContext } from "react";
import { LockeysHolderType } from "../../logic/types";

type IGeneralContext = Partial<{
  lockeysHolderType: LockeysHolderType | null;
  modalConnectWallets: [boolean, (nextValue?: boolean) => void];
  modalSign: [boolean, (nextValue?: boolean) => void];
  modalSwitchNetwork: [boolean, (nextValue?: boolean) => void];
}>;

type IAlertContext = Partial<{
  alertNotification: [
    boolean,
    (
      type: "error" | "success" | "warning" | "loading" | "info",
      content: string,
      timeout: number
    ) => void,
    () => void
  ];
}>;

export const GeneralContext = createContext<IGeneralContext>({});
export const AlertContext = createContext<IAlertContext>({});
