import ActionContract from "./ActionContract";
import BulkTransferContract from "./BulkTransferContract";
import DelegationWalletFactoryContract from "./DelegationWalletFactoryContract";
import { DelegationWalletRegistryContract } from "./DelegationWalletRegistryContract";
import UTokenFactoryContract from "./UTokenFactoryContract";
import WrappedTokenContract from "./WethContract";
import SellNowContract from "./SellNowContract";
import MarketContract from "./MarketContract";
import UnlockdContract from "./UnlockdContract";
import AuctionContract from "./AuctionContract";
import MarketEventsContract from "./MarketEventsContract";
import AuctionEventsContract from "./AuctionEventsContract";
import { Address } from "viem";
import EarnWethGatewayContract from "./EarnWethGatewayContract";
import { AddressProvider } from "@unlockdfinance/verislabs-web3";
import { app } from "config/app.config";
import { SupportedChainIds, verisModule } from "clients/verisModule";

const unlockdContract = new UnlockdContract();

const earnWethGateway = new EarnWethGatewayContract();

const delegationWalletFactoryContract = new DelegationWalletFactoryContract();

const delegationWalletRegistryContract = new DelegationWalletRegistryContract();

const bulkTransferContract = new BulkTransferContract();

const uTokenFactoryContract = new UTokenFactoryContract();

const wrappedTokenContract = new WrappedTokenContract();

const actionContract = new ActionContract(app.UNLOCKD_MODULES.ACTION);

const sellNowContract = new SellNowContract(app.UNLOCKD_MODULES.SELLNOW);

const marketContract = new MarketContract(app.UNLOCKD_MODULES.MARKET);

const auctionContract = new AuctionContract(app.UNLOCKD_MODULES.AUCTION);

const marketEventsContract = new MarketEventsContract();

const auctionEventsContract = new AuctionEventsContract();

AddressProvider.init(
  new (class extends AddressProvider {
    lookupAddress(id: string, chainId: SupportedChainIds): Promise<Address> {
      return unlockdContract.moduleIdToProxy(
        BigInt(id),
        chainId
      );
    }
  })()
);

export {
  earnWethGateway,
  delegationWalletFactoryContract,
  delegationWalletRegistryContract,
  bulkTransferContract,
  actionContract,
  uTokenFactoryContract,
  wrappedTokenContract,
  sellNowContract,
  marketContract,
  marketEventsContract,
  unlockdContract,
  auctionContract,
  auctionEventsContract,
};
