import { VerisContract } from "@unlockdfinance/verislabs-web3";
import { Abi } from "viem";
import { verisModule } from "../clients/verisModule";
import { ContractName } from "vendor/@unlockdfinance/verislabs-web3/src/contracts/VerisContract";
import { Address } from "vendor/@unlockdfinance/verislabs-web3/src/types";

export type UnlockdContractName = ContractName<typeof verisModule>;

export default abstract class AbstractContract<
  TAbi extends Abi
> extends VerisContract<TAbi, typeof verisModule> {
  constructor(
    {
      contractName,
      moduleId,
      address,
    }: {
      contractName?: UnlockdContractName;
      moduleId?: string;
      address?: Address;
    },
    abi: TAbi
  ) {
    super(verisModule, { contractName, moduleId, address }, abi);
  }
}
