import { Address } from "viem";
import { chainlinkPriceFeedAbi } from "./abis";
import VerisContract from "./VerisContract";
import IVerisModule from "../IVerisModule";

export default class ChainlinkPriceFeed extends VerisContract<
  typeof chainlinkPriceFeedAbi
> {
  constructor(verisModule: IVerisModule, address: Address) {
    super(verisModule, { address }, chainlinkPriceFeedAbi);
  }

  async latestAnswer(): Promise<bigint> {
    return (await this.viemReadContract).read.latestAnswer();
  }
}
