import { ICurrency } from "logic/types/currency/ICurrency";
import { formatCurrencyAmount } from "vendor/@unlockdfinance/verislabs-web3/src/utils";

export default function formatCurrencyValue(
  value: bigint,
  currency: ICurrency,
  decimals?: number,
  decimalsToShow?: number
): string {
  const _decimals = decimals || currency.decimals;
  const _decimalsToShow = decimalsToShow || currency.decimalsToShow;

  return formatCurrencyAmount(value, _decimals, _decimalsToShow);
}
