import { Address, erc20Abi } from "viem";
import VerisContract from "./VerisContract";
import IVerisModule from "../IVerisModule";
import { Output } from "../Output";
import { OptionsWriteMethod, VerisChainId, VerisNetwork } from "../types";

export default class Erc20Contract extends VerisContract<
  typeof erc20Abi,
  IVerisModule<Record<string, Address>, VerisNetwork<Record<string, Address>>[]>
> {
  constructor(
    verisModule: IVerisModule<
      Record<string, Address>,
      VerisNetwork<Record<string, Address>>[]
    >,
    address: Address
  ) {
    super(verisModule, { address }, erc20Abi);
  }

  async balanceOf(address: Address, chainId: VerisChainId) {
    return (await this.viemReadContract(chainId)).read.balanceOf([address]);
  }

  async approve(
    spender: Address,
    amount: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return this.verisModule.walletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract).write.approve([spender, amount]),
      options
    );
  }
}
