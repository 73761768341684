import { Address } from "viem";
import { chainlinkPriceFeedAbi } from "./abis/chainlinkPriceFeedAbi";
import AbstractContract from "./AbstractContract";
import { SupportedChainIds } from "clients/verisModule";

export default class ChainlinkPriceFeed extends AbstractContract<
  typeof chainlinkPriceFeedAbi
> {
  constructor(address: Address) {
    super({ address }, chainlinkPriceFeedAbi);
  }

  async latestAnswer(chainId: SupportedChainIds): Promise<bigint> {
    return (await this.viemReadContract(chainId)).read.latestAnswer();
  }
}
