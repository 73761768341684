import type { Address } from "viem";
import { delegationWalletFactoryAbi } from "./abis/delegationWalletFactoryAbi";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import { externalWalletModule } from "../clients/verisModule";
import AbstractContract from "./AbstractContract";

export default class DelegationWalletFactoryContract extends AbstractContract<
  typeof delegationWalletFactoryAbi
> {
  constructor() {
    super({ contractName: "walletFactory" }, delegationWalletFactoryAbi);
  }

  async deploy(
    externalAccount: Address,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    const delegationController = this.verisModule.getContractAddress(
      "delegationController"
    );

    return externalWalletModule.handleViemTransaction(
      async () =>
        (await this.viemWriteContract()).write.deploy([delegationController], {
          account: externalAccount,
          chain: undefined,
        }),
      options
    );
  }
}
