import { Address } from "viem";
import { delegationWalletRegistryContract } from "../contracts";
import {
  SupportedChainIds,
  externalWalletModule,
  verisModule,
} from "../clients/verisModule";
import { getBalance } from "@wagmi/core";
import { ICurrency, IErc20Currency } from "./types/currency/ICurrency";
import { AssetTransfersCategory, SortingOrder } from "alchemy-sdk";
import TransferFactory from "./types/transfers/TransferFactory";
import { ITransfer } from "./types/transfers/ITransfer";
import { Erc20Currency } from "./types/currency/Currency";
import { INft } from "./types/nft/INft";
import UnlockdWalletContract from "contracts/UnlockdWalletContract";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";

export type UnlockdWalletSubsCallback = (
  unlockdAddress: Address | null | undefined
) => void;

class UnlockdWalletModule {
  private subscriptions: UnlockdWalletSubsCallback[] = [];
  private _unlockdAddress: Address | null | undefined;

  constructor() {
    externalWalletModule.subscribe(async (externalAddress, chainId, _) => {

      this.unlockdAddress = undefined;

      if (externalAddress) {
        const _chainId = verisModule.networks.find(network => network.CHAIN.id === chainId)?.CHAIN.id || verisModule.currentNetworkConfig.CHAIN.id

        const unlockdAddress = await this.getSafeWallet(
          externalAddress,
          _chainId
        );

        this.unlockdAddress = unlockdAddress;

        this.subscriptions.forEach((callback) => {
          callback(this.unlockdAddress);
        });
      } else {
        this.subscriptions.forEach((callback) => {
          callback(undefined);
        });
      }
    });
  }

  get alchemy() {
    return verisModule.getAlchemyClient();
  }

  get unlockdAddress() {
    return this._unlockdAddress;
  }

  set unlockdAddress(newAddress: Address | null | undefined) {
    this._unlockdAddress = newAddress;
  }

  async getSafeWallet(
    externalAccount: Address,
    chainId: SupportedChainIds
  ): Promise<Address | null> {
    const addresses =
      await delegationWalletRegistryContract.getOwnerWalletAddresses(
        externalAccount,
        chainId
      );

    return addresses[0] || null;
  }

  async getNativeBalance() {
    if (!this.unlockdAddress) {
      // throw new Error("No address connected");
      return BigInt(0);
    }

    const { value } = await getBalance(verisModule.wagmiConfig, {
      address: this.unlockdAddress,
    });

    return value;
  }

  async getErc20Transfers(currency: IErc20Currency, chainId: SupportedChainIds): Promise<ITransfer[]> {
    const address = this.unlockdAddress;

    if (!address) {
      throw new Error("No address connected");
    }

    const [
      { transfers: transfersCredit, pageKey: pageKeyCredit },
      { transfers: transfersDebit, pageKey: pageKeyDebit },
    ] = await Promise.all([
      this.alchemy.core.getAssetTransfers({
        category: [AssetTransfersCategory.ERC20],
        contractAddresses: [currency.address],
        toAddress: address,
        order: SortingOrder.DESCENDING,
        withMetadata: true,
      }),
      this.alchemy.core.getAssetTransfers({
        category: [AssetTransfersCategory.ERC20],
        contractAddresses: [currency.address],
        fromAddress: address,
        order: SortingOrder.DESCENDING,
        withMetadata: true,
      }),
    ]);

    const transfers = transfersCredit
      .concat(transfersDebit)
      .sort((a, b) => Number(b.blockNum) - Number(a.blockNum));

    return transfers.map((transfer) =>
      TransferFactory.create(transfer, address, chainId)
    );
  }

  async getNativeCurrencyTransfers(chainId: SupportedChainIds): Promise<ITransfer[]> {
    const address = this.unlockdAddress;

    if (!address) {
      throw new Error("No address connected");
    }

    const [
      { transfers: transfersCredit, pageKey: pageKeyCredit },
      { transfers: transfersDebit, pageKey: pageKeyDebit },
    ] = await Promise.all([
      this.alchemy.core.getAssetTransfers({
        category: [
          AssetTransfersCategory.INTERNAL,
          AssetTransfersCategory.EXTERNAL,
        ],
        toAddress: address,
        order: SortingOrder.DESCENDING,
        withMetadata: true,
      }),
      this.alchemy.core.getAssetTransfers({
        category: [
          AssetTransfersCategory.INTERNAL,
          AssetTransfersCategory.EXTERNAL,
        ],
        fromAddress: address,
        order: SortingOrder.DESCENDING,
        withMetadata: true,
      }),
    ]);

    const transfers = transfersCredit
      .concat(transfersDebit)
      .sort((a, b) => Number(b.blockNum) - Number(a.blockNum));

    return transfers.map((transfer) =>
      TransferFactory.create(transfer, address, chainId)
    );
  }

  async getAssetTransfers(currency: ICurrency, chainId: SupportedChainIds): Promise<ITransfer[]> {
    if (currency instanceof Erc20Currency) {
      return this.getErc20Transfers(currency, chainId);
    } else {
      return this.getNativeCurrencyTransfers(chainId);
    }
  }

  subscribe(callback: UnlockdWalletSubsCallback) {
    this.subscriptions.push(callback);
  }

  unsubscribe(callback: UnlockdWalletSubsCallback) {
    const index = this.subscriptions.findIndex(
      (_callback) => callback === _callback
    );

    if (index > -1) {
      this.subscriptions.splice(index, 1);
    }
  }

  async withdrawAssets(
    nfts: INft[],
    to: Address,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    const address = this.unlockdAddress;

    if (!address) {
      throw new Error("No address connected");
    }

    const parsedNfts = nfts.map((nft) => ({
      contractAddress: nft.collection,
      tokenId: BigInt(nft.tokenId),
    }));

    return new UnlockdWalletContract(address).withdrawAssets(
      parsedNfts,
      to,
      options
    );
  }
}

export default new UnlockdWalletModule();
