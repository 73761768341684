import IVerisModule from "../IVerisModule";
import _ from "lodash";
/**
 * Manages session or local storage with a JSON structure.
 *
 * @example
 * ```js
 * const storage = Storage.get(localStorage);
 *
 * const txts = storage.get('accounts.something.history')
 *
 * txts.push({ id: '...'})
 *
 * storage.set('accounts.something.history', txts)
 * ```
 */
export default class VerisStorage {
  private target: Storage;
  private verisModule: IVerisModule;

  constructor(verisModule: IVerisModule, target: Storage) {
    this.target = target;
    this.verisModule = verisModule;
  }

  __retrieve() {
    return this.target.store ? JSON.parse(this.target.store) : {};
  }

  __persist(store: object) {
    this.target.store = JSON.stringify(store);
  }

  set(path: string, value: any) {
    const store = this.__retrieve();

    _.set(store, path, value);

    this.__persist(store);
  }

  get(path: string, defaultValue?: any) {
    const store = this.__retrieve();

    return _.get(store, path, defaultValue);
  }
}
