import { UnexpectedError } from '../../errors'

export default function safaryTrack(eventType: string, eventName: string, parameters?: object, maxAttemps: number = 3): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        let attempts = 0

        function tryTrack() {
            // @ts-ignore
            if (typeof safary !== 'undefined')
                // @ts-ignore
                safary.track({ eventType, eventName, parameters })
                    .then(resolve)
                    .catch((error: Error) => reject(new UnexpectedError(error.message)))
            else if (attempts++ < maxAttemps)
                setTimeout(() => tryTrack(), 1000)
            else reject(new UnexpectedError(`max attemps reached (${maxAttemps})`))
        }

        tryTrack()
    })
}