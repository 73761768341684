import {
  metamaskErrorsMap,
  TransactionError,
  UnexpectedError,
  UserDeniedTransactionSignatureError,
} from ".";
import { lifeCycleLiterals } from "../literals";

export type Flow = keyof typeof lifeCycleLiterals;

const handleErrors = (error: any, flow?: Flow): Error => {
  console.error(error);

  if (error.code && metamaskErrorsMap[error.code]?.eng) {
    return new Error(metamaskErrorsMap[error.code].eng);
  } else if (
    error instanceof TransactionError ||
    error instanceof UserDeniedTransactionSignatureError
  ) {
    return error;
  } else if (error.shortMessage === "User rejected the request.") {
    return new Error(metamaskErrorsMap["4001"].eng);
  } else if (error.shortMessage?.includes("The current chain of the wallet")) {
    return new Error("The current chain of the wallet is not supported.");
  } else if (flow) {
    return new UnexpectedError(lifeCycleLiterals[flow].onError);
  }

  return new UnexpectedError("unexpected error");
};

export default handleErrors;
