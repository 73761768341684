import VerisModule from "./VerisModule";
import { WalletModule, WalletSubsCallback } from "./WalletModule";
import VerisContract from "./contracts/VerisContract";
import ChainlinkPriceFeed from "./contracts/ChainlinkPriceFeed";
import Erc20Contract from "./contracts/Erc20Contract";
import type IVerisModule from "./IVerisModule";
import AddressProvider from "./contracts/helpers/AddressesProvider";
import { Output } from "./Output";
import {
  Address,
  Hash,
  ChainName,
  CollectionData,
  CurrencyData,
  Erc20CurrencyData,
  Networks,
  OptionsWriteMethod,
  VerisChain,
  VerisNetwork,
} from "./types";

export {
  VerisModule,
  WalletModule,
  VerisContract,
  Erc20Contract,
  ChainlinkPriceFeed,
  AddressProvider,
  Output,
};

export type {
  WalletSubsCallback,
  IVerisModule,
  Address,
  Hash,
  ChainName,
  CollectionData,
  CurrencyData,
  Erc20CurrencyData,
  Networks,
  OptionsWriteMethod,
  VerisChain,
  VerisNetwork,
};
