import { Address, erc20Abi } from "viem";
import {
  SupportedChainIds,
  externalWalletModule,
} from "../clients/verisModule";
import {
  Hash,
  OptionsWriteMethod,
  Output,
} from "@unlockdfinance/verislabs-web3";
import AbstractContract from "./AbstractContract";
import { WalletType } from "./types";

export default class Erc20Contract extends AbstractContract<typeof erc20Abi> {
  constructor(address: Address) {
    super({ address }, erc20Abi);
  }

  async balanceOf(address: Address, chainId: SupportedChainIds) {
    return (await this.viemReadContract(chainId)).read.balanceOf([address]);
  }

  async allowance(
    owner: Address,
    spender: Address,
    chainId: SupportedChainIds
  ) {
    return (await this.viemReadContract(chainId)).read.allowance([
      owner,
      spender,
    ]);
  }

  async approve(
    spender: Address,
    amount: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.approve([spender, amount]),
      options
    );
  }

  transfer(
    from: WalletType,
    to: Address,
    amount: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.transfer([to, amount]),
      options
    );
  }
}
