import {
  Abi,
  Address,
  PublicClient,
  WalletClient,
  getContract,
  GetContractReturnType,
} from "viem";
import { encodeFunctionData } from "viem";
import AddressProvider from "./helpers/AddressesProvider";
import IVerisModule from "../IVerisModule";
import { VerisChainId, VerisNetwork } from "../types";

type ExtractContractAddresses<T> = T extends {
  networks: { CONTRACT_ADDRESSES: Record<string, Address> }[];
}
  ? T["networks"][number]["CONTRACT_ADDRESSES"]
  : never;

type GetKeys<T extends Record<string, any>> = {
  [K in keyof T]: K;
}[keyof T];

export type ContractName<
  TVerisModule extends IVerisModule<
    Record<string, Address>,
    readonly VerisNetwork<Record<string, Address>>[]
  >
> = GetKeys<ExtractContractAddresses<TVerisModule>>;

export default abstract class VerisContract<
  TAbi extends Abi,
  TVerisModule extends IVerisModule<
    ExtractContractAddresses<TVerisModule>,
    VerisNetwork<ExtractContractAddresses<TVerisModule>>[]
  >
> {
  private _addresses: Map<VerisChainId, Address> = new Map();
  private address?: Address;
  private _abi: TAbi;
  private _moduleId?: string;
  private contractName?: ContractName<TVerisModule>;
  verisModule: TVerisModule;

  constructor(
    verisModule: TVerisModule,
    {
      contractName,
      moduleId,
      address,
    }: {
      contractName?: ContractName<TVerisModule>;
      moduleId?: string;
      address?: Address;
    },
    abi: TAbi
  ) {
    if (!contractName && !moduleId && !address)
      throw new Error(
        "Contract must be initialized with either an address, contractName or a moduleId"
      );

    if (address) {
      this.address = address;
    }

    if (contractName) {
      this.contractName = contractName;
    }

    if (moduleId) {
      this._moduleId = moduleId;
    }

    this._abi = abi;
    this.verisModule = verisModule;
  }

  private async _getAddress(chainId: VerisChainId) {
    if (this.address) {
      return this.address;
    } else if (this.contractName) {
      this._addresses.set(
        chainId,
        this.verisModule.getContractAddress(this.contractName, chainId)
      );
    } else if (!this._addresses.has(chainId)) {
      this._addresses.set(
        chainId,
        await AddressProvider.get().getAddress(this._moduleId!, chainId)
      );
    }

    return this._addresses.get(chainId)!;
  }

  getAddress(
    chainId: VerisChainId = this.verisModule.currentNetworkConfig.CHAIN.id
  ) {
    return this.address || this._addresses.get(chainId);
  }

  get abi() {
    return this._abi;
  }

  viemWriteContract(
    chainId: VerisChainId = this.verisModule.currentNetworkConfig.CHAIN.id
  ): Promise<GetContractReturnType<TAbi, WalletClient, Address>> {
    return new Promise(async (resolve) => {
      const writeClient = this.verisModule.lookUpClient("write", chainId);

      // if (writeClient === this._writeClient && this._viemWriteContract) {
      //   resolve(
      //     this._viemWriteContract as GetContractReturnType<
      //       TAbi,
      //       WalletClient,
      //       Address
      //     >
      //   );
      // } else {
      // this._writeClient = writeClient;

      const config: {
        abi: Abi;
        address: Address;
        client: {
          public: PublicClient;
          wallet?: WalletClient;
        };
      } = {
        address: await this._getAddress(chainId),
        abi: this.abi,
        client: {
          public: writeClient as PublicClient,
          wallet: writeClient as WalletClient,
        },
      };

      const contract = getContract(config);

      // this._viemWriteContract = contract;

      resolve(
        contract as unknown as GetContractReturnType<
          TAbi,
          WalletClient,
          Address
        >
      );
      // }
    });
  }

  viemReadContract(
    chainId: VerisChainId = this.verisModule.currentNetworkConfig.CHAIN.id
  ): Promise<GetContractReturnType<TAbi, PublicClient, Address>> {
    return new Promise(async (resolve) => {
      const readonlyClient = this.verisModule.lookUpClient("readOnly", chainId);

      // if (readonlyClient === this._readonlyClient && this._viemReadContract) {
      //   resolve(
      //     this._viemReadContract as GetContractReturnType<
      //       TAbi,
      //       PublicClient,
      //       Address
      //     >
      //   );
      // } else {
      // this._readonlyClient = readonlyClient;

      const config: {
        abi: Abi;
        address: Address;
        client: { public: PublicClient; wallet?: WalletClient };
      } = {
        address: await this._getAddress(chainId),
        abi: this.abi,
        client: { public: readonlyClient as PublicClient },
      };

      const contract = getContract(config);

      // this._viemReadContract = contract;

      resolve(
        contract as unknown as GetContractReturnType<
          TAbi,
          PublicClient,
          Address
        >
      );
      // }
    });
  }

  encodeFunctionData(functionName: string, args?: any[]): string {
    return encodeFunctionData({ abi: this.abi as Abi, args, functionName });
  }
}
