import { Address, WatchContractEventReturnType } from "viem";
import { marketAbi } from "./abis/marketAbi";
import AbstractContract from "./AbstractContract";
import { SupportedChainIds } from "clients/verisModule";

export type BidArgs = {
  loanId?: `0x${string}` | `0x${string}`[] | null | undefined;
  orderId?: `0x${string}` | `0x${string}`[] | null | undefined;
  assetId?: `0x${string}` | `0x${string}`[] | null | undefined;
};

export type CancelArgs = {
  loanId?: `0x${string}` | `0x${string}`[] | null | undefined;
  orderId?: `0x${string}` | `0x${string}`[] | null | undefined;
};

export type BidTopics = {
  loanId?: `0x${string}`;
  orderId?: `0x${string}`;
  assetId?: `0x${string}`;
  amount?: bigint;
  user?: `0x${string}`;
  isMarketItem: boolean;
};

export type CancelTopics = {
  loanId?: `0x${string}`;
  orderId?: `0x${string}`;
  owner?: `0x${string}`;
};

export type BoughtTopics = {
  loanId?: `0x${string}`;
  orderId?: `0x${string}`;
  assetId?: `0x${string}`;
  amount?: bigint;
  user?: `0x${string}`;
};

export default class MarketEventsContract extends AbstractContract<
  typeof marketAbi
> {
  constructor() {
    super({ contractName: "unlockd" }, marketAbi);
  }

  async onOrderCancelled(
    args: CancelArgs,
    onLogs: (logs: CancelTopics) => void,
    chainId: SupportedChainIds
  ): Promise<WatchContractEventReturnType> {
    return (
      await this.viemReadContract(chainId)
    ).watchEvent.MarketCancelAuction(args, {
      onLogs: (logs) => onLogs(logs[0].args),
    });
  }

  async onBought(
    args: BidArgs,
    onLogs: (logs: BoughtTopics) => void,
    chainId: SupportedChainIds
  ): Promise<WatchContractEventReturnType> {
    return (await this.viemReadContract(chainId)).watchEvent.MarketBuyNow(
      args,
      {
        onLogs: (logs) => onLogs(logs[0].args),
      }
    );
  }

  async onBid(
    args: BidArgs,
    onLogs: (logs: BidTopics) => void,
    chainId: SupportedChainIds
  ): Promise<WatchContractEventReturnType> {
    return (await this.viemReadContract(chainId)).watchEvent.MarketBid(args, {
      onLogs: (logs) => onLogs({ ...logs[0].args, isMarketItem: true }),
    });
  }
}
