import { sellNowAbi } from "./abis/sellNowAbi";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import { externalWalletModule } from "../clients/verisModule";
import AbstractContract from "./AbstractContract";
import { SimpleNft } from "logic/types/nft/INft";

export default class SellNowContract extends AbstractContract<
  typeof sellNowAbi
> {
  constructor(moduleId: string) {
    super({ moduleId }, sellNowAbi);
  }

  sellNow(
    nft: SimpleNft,
    data: any,
    signature: any,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.sell([nft, data, signature]),
      options
    );
  }
}
