import { Address } from "viem";
import { wEthAbi } from "./abis/wEthAbi";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import { externalWalletModule } from "../clients/verisModule";
import AbstractContract from "./AbstractContract";

export default class WrappedTokenContract extends AbstractContract<
  typeof wEthAbi
> {
  constructor() {
    super({ contractName: "wrappedToken" }, wEthAbi);
  }

  approve(
    to: Address,
    amount: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      // @ts-ignore
      async () => (await this.viemWriteContract()).write.approve([to, amount]),
      options
    );
  }

  deposit(value: bigint, options?: OptionsWriteMethod): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        (await this.viemWriteContract()).write.deposit({
          account: externalWalletModule.address!,
          value,
          chain: undefined,
        }),
      options
    );
  }

  withdraw(
    amount: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.withdraw([amount]),
      options
    );
  }
}
