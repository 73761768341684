import {
  Address,
  OptionsWriteMethod,
  Output,
} from "@unlockdfinance/verislabs-web3";
import { UnlockdWalletAbi } from "./abis/UnlockdWalletAbi";
import AbstractContract from "./AbstractContract";
import { externalWalletModule } from "clients/verisModule";

export default class UnlockdWalletContract extends AbstractContract<
  typeof UnlockdWalletAbi
> {
  constructor(address: Address) {
    super({ address }, UnlockdWalletAbi);
  }

  withdrawAssets(
    nfts: { contractAddress: Address; tokenId: bigint }[],
    to: Address,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.withdrawAssets(nfts, to),
      options
    );
  }
}
