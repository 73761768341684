import {
  Address,
  OptionsWriteMethod,
  VerisContract,
} from "@unlockdfinance/verislabs-web3";
import { IErc20Currency } from "../currency/ICurrency";
import { Abi } from "viem";
import { customERC721Abi } from "contracts/abis/customERC721";
import { ERC721Contract } from "contracts/ERC721Contract";
import { WalletType } from "contracts/types";
import { CollectionData } from "vendor/@unlockdfinance/verislabs-web3/src/types/CollectionData";
import Chain from "../chain/Chain";

export enum BulkApprovalStatus {
  PENDING = "Pending",
  APPROVING = "Approving",
  APPROVED = "Approved",
}

export enum CollectionCategory {
  WATCHES = "WATCHES",
  COLLECTIBLES = "COLLECTIBLES",
  FINANCIAL = "FINANCIAL",
  GEMS = "GEMS",
  WINE = "WINE",
  REAL_ESTATE = "REAL_ESTATE",
}

export type UnlockdCollectionData = CollectionData & {
  category: CollectionCategory;
};

export type BulkSubsCallback = (bulkTansferStatus: BulkApprovalStatus) => void;

export interface ICollection<TAbi extends Abi> {
  name: string;
  address: Address;
  genericImage: string;
  currenciesSupported: IErc20Currency[];
  category: CollectionCategory;
  contract: VerisContract<TAbi>;
  chain: Chain;
}

export interface IErc721Collection extends ICollection<typeof customERC721Abi> {
  contract: ERC721Contract;
  approveBulkTransfer: (
    walletType?: WalletType,
    options?: OptionsWriteMethod
  ) => Promise<void>;
  getBulkApprovalStatus: (address: Address) => Promise<BulkApprovalStatus>;
  getBulkApprovalStatusSync: (address: Address) => BulkApprovalStatus;
  subscribe: (address: Address, callback: BulkSubsCallback) => () => void;
  unsubscribe: (address: Address, callback: BulkSubsCallback) => void;
}
