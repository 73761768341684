import { Address, WatchContractEventReturnType } from "viem";
import { uTokenFactoryAbi } from "./abis/uTokenFactoryAbi";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import {
  SupportedChainIds,
  externalWalletModule,
} from "../clients/verisModule";
import AbstractContract from "./AbstractContract";

export type SupplyTopics = {
  user?: `0x${string}` | `0x${string}`[] | undefined;
  onBehalfOf?: `0x${string}` | `0x${string}`[] | undefined;
  underlyingAsset?: `0x${string}` | `0x${string}`[] | undefined;
  amount?: bigint | undefined;
};

export type WithdrawTopics = {
  user?: `0x${string}` | undefined;
  underlyingAsset?: `0x${string}` | undefined;
  amount?: bigint | undefined;
  to?: `0x${string}` | undefined;
};

export enum ReserveState {
  STOPPED, // No supply, No borrow
  FREEZED, // No supply, No withdraw , No borrow, No repay
  ACTIVE, // All OK
}

export type ReserveData = {
  config: any;
  liquidityIndex: bigint;
  variableBorrowIndex: bigint;
  currentLiquidityRate: bigint;
  currentVariableBorrowRate: bigint;
  underlyingAsset: Address;
  scaledTokenAddress: Address;
  interestRateAddress: Address;
  strategyAddress: Address;
  lastUpdateTimestamp: number;
  // reserveType: number;
  // reserveState: ReserveState;
  // reserveFactor: number;
  // decimals: number;
};

export default class UTokenFactoryContract extends AbstractContract<
  typeof uTokenFactoryAbi
> {
  constructor() {
    super({ contractName: "uTokenFactory" }, uTokenFactoryAbi);
  }

  async balanceOf(
    underlyingAsset: Address,
    address: Address,
    chainId: SupportedChainIds
  ): Promise<bigint> {
    return (await this.viemReadContract(chainId)).read.getBalanceByUser([
      underlyingAsset,
      address,
    ]);
  }

  async scaledBalanceOf(
    underlyingAsset: Address,
    address: Address,
    chainId: SupportedChainIds
  ): Promise<bigint> {
    return (await this.viemReadContract(chainId)).read.getScaledBalanceByUser([
      underlyingAsset,
      address,
    ]);
  }

  async totalAvailableSupply(
    underlyingAsset: Address,
    chainId: SupportedChainIds
  ): Promise<bigint> {
    return (await this.viemReadContract(chainId)).read.totalAvailableSupply([
      underlyingAsset,
    ]);
  }

  async totalSupply(
    underlyingAsset: Address,
    chainId: SupportedChainIds
  ): Promise<bigint> {
    return (await this.viemReadContract(chainId)).read.totalSupply([
      underlyingAsset,
    ]);
  }

  async getReserveData(
    underlyingAsset: Address,
    chainId: SupportedChainIds
  ): Promise<ReserveData> {
    return (await this.viemReadContract(chainId)).read.getReserveData([
      underlyingAsset,
    ]);
  }

  // returns a tuple with booleans that represent the following states:
  // active
  // frozen
  // paused
  async getFlags(underlyingAsset: Address, chainId: SupportedChainIds) {
    return (await this.viemReadContract(chainId)).read.getFlags([
      underlyingAsset,
    ]);
  }

  async getDebtFromLoanId(
    underlyingAsset: Address,
    loanId: Address,
    chainId: SupportedChainIds
  ) {
    return (await this.viemReadContract(chainId)).read.getDebtFromLoanId([
      underlyingAsset,
      loanId,
    ]);
  }

  async getScaledTotalDebtMarket(
    underlyingAsset: Address,
    chainId: SupportedChainIds
  ) {
    return (await this.viemReadContract(chainId)).read.getScaledTotalDebtMarket(
      [underlyingAsset]
    );
  }

  async getScaledToken(underlyingAsset: Address, chainId: SupportedChainIds) {
    return (await this.viemReadContract(chainId)).read.getScaledToken([
      underlyingAsset,
    ]);
  }

  async getScaledTotalDebtFromUser(
    underlyingAsset: Address,
    user: Address,
    chainId: SupportedChainIds
  ): Promise<bigint> {
    return (
      await this.viemReadContract(chainId)
    ).read.getScaledTotalDebtFromUser([underlyingAsset, user]);
  }

  deposit(
    underlyingAsset: Address,
    amount: bigint,
    onBehalfOf: Address,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.deposit([
          underlyingAsset,
          amount,
          onBehalfOf,
        ]),
      options
    );
  }

  withdraw(
    underlyingAsset: Address,
    amount: bigint,
    to: Address,
    options?: OptionsWriteMethod
  ) {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.withdraw([
          underlyingAsset,
          amount,
          to,
        ]),
      options
    );
  }

  async onDeposit(
    address: Address,
    onLogs: (depositTopics: SupplyTopics) => void,
    chainId: SupportedChainIds
  ): Promise<WatchContractEventReturnType> {
    return (await this.viemReadContract(chainId)).watchEvent.Deposit(
      { onBehalfOf: address },
      { onLogs: (logs) => onLogs(logs[0].args) }
    );
  }

  async onWithdraw(
    address: Address,
    onLogs: (withdrawTopics: WithdrawTopics) => void,
    chainId: SupportedChainIds
  ): Promise<WatchContractEventReturnType> {
    return (await this.viemReadContract(chainId)).watchEvent.Withdraw(
      { to: address },
      { onLogs: (logs) => onLogs(logs[0].args) }
    );
  }
}
