import { SupportedChainIds } from "clients/verisModule";
import { Address } from "viem";

export default abstract class AddressProvider {
  map: { [key: string]: Address } = {};
  private static instance: AddressProvider;

  static init(instance: AddressProvider) {
    this.instance = instance;
  }

  static get(): AddressProvider {
    return this.instance;
  }

  async getAddress(id: string, chainId: SupportedChainIds): Promise<Address> {
    const key = `${id}-${chainId}`;

    if (!this.map[key]) {
      const address = await this.lookupAddress(id, chainId);

      this.map[key] = address;
    }

    return this.map[key];
  }

  abstract lookupAddress(...args: any[]): Promise<Address>;
}
