import { Address, Hash } from "@unlockdfinance/verislabs-web3";
import { ITransfer, TransferMethod } from "./ITransfer";
import { ICurrency } from "../currency/ICurrency";
import { toDaysAndHoursAgo } from "utils";

export default class Transfer implements ITransfer {
  txHash: Hash;
  method: TransferMethod;
  timestamp: number;
  from: Address;
  to: Address;
  value: bigint;
  currency: ICurrency;

  constructor({
    txHash,
    method,
    timestamp,
    from,
    to,
    value,
    currency,
  }: {
    txHash: Hash;
    method: TransferMethod;
    timestamp: number;
    from: Address;
    to: Address;
    value: bigint;
    currency: ICurrency;
  }) {
    this.txHash = txHash;
    this.method = method;
    this.timestamp = timestamp;
    this.from = from;
    this.to = to;
    this.value = value;
    this.currency = currency;
  }

  get valueFormated() {
    return this.currency.formatAmount(this.value);
  }

  get dateFormated() {
    return toDaysAndHoursAgo(new Date(this.timestamp));
  }
}
