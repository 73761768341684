import { Address, ChainName } from "../types";

export type NetworkAddresses = {
  chainLinkEthUsdPriceFeed: Address;
};

export type NetworksAddresses = {
  [key in ChainName]: NetworkAddresses;
};

export const CONTRACT_ADDRESSES: NetworksAddresses = {
  sepolia: {
    chainLinkEthUsdPriceFeed: "0x694AA1769357215DE4FAC081bf1f309aDC325306",
  },
  mainnet: {
    chainLinkEthUsdPriceFeed: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
  },
};
