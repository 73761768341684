import { Address } from "viem";
import { earnWethGatewayAbi } from "./abis/earnWethGatewayAbi";
import AbstractContract from "./AbstractContract";
import { externalWalletModule } from "clients/verisModule";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";

export default class EarnWethGatewayContract extends AbstractContract<
  typeof earnWethGatewayAbi
> {
  constructor() {
    super({ contractName: "earnWethGateway" }, earnWethGatewayAbi);
  }

  depositETH(
    onBehalfOf: Address,
    value: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.depositETH([onBehalfOf], {
          value,
        }),
      options
    );
  }

  withdrawETH(amount: bigint, to: Address, options?: OptionsWriteMethod) {
    return externalWalletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract()).write.withdrawETH([amount, to]),
      options
    );
  }
}
